



























import Vue from "vue";
import userManager from "@/services/userManager";

export default Vue.extend({
  data() {
    return {
      self: this,
      userManager,
      item: null,
      currentTab: "",
      tabs: {
        all: {
          label: "All Orders",
        },
        open: {
          label: "Open Orders",
        },
        processing: {
          label: "Processing Orders",
        },
        shipping: {
          label: "Shipping Orders",
        },
        completed: {
          label: "Completed Orders",
        },
        cancelled: {
          label: "Cancelled Orders",
        },
      },
    };
  },
});
